import { Capacitor } from "@capacitor/core"
import { Browser } from "@capacitor/browser"
import { AppLauncher } from "@capacitor/app-launcher"

/**
 * navigate to google maps page
 * @param place
 */

async function openMaps(location) {
  if (location.lat !== undefined && location.lng !== undefined) {
    googlePlaces({
      google: `https://www.google.com/maps/search/?api=1&query=${location.lat}%2C${location.lng}`,
      apple: `https://maps.apple.com/?daddr=${location.lat},${location.lng}`,
    })
  } else {
    let fullAddress = encodeURIComponent(`${location}, Virginia Beach, VA`)
    googlePlaces({
      google: `https://www.google.com/maps/place/${fullAddress}`,
      apple: `https://maps.apple.com/?address=${fullAddress}`,
    })
  }
}

async function googlePlaces(place) {
  var letOpenApp = false
  var url = place.google

  if (Capacitor.getPlatform() === "ios") {
    url = place.apple

    try {
      letOpenApp = await AppLauncher.canOpenUrl({
        url: "https://maps.apple.com",
      })
    } catch (e) {
      // console.log(e)
    }
  }

  if (Capacitor.getPlatform() === "android") {
    try {
      letOpenApp = await AppLauncher.canOpenUrl({
        url: "com.google.android.apps.maps",
      })
    } catch (e) {
      // console.log(e)
    }
  }

  letOpenApp = letOpenApp.value

  if (letOpenApp) {
    await AppLauncher.openUrl({
      url: url,
    })
  } else {
    await Browser.open({ url })
  }
}

/**
 * check to see if time has expired
 * @param { timeStamp } timeStamp string
 */
function checkForExpiredToken(timeStamp) {
  // console.log("check expires in =========== " + new Date(timeStamp * 1000))
  if (new Date(timeStamp * 1000) > new Date()) {
    return false
  }

  return true
}

export { googlePlaces, checkForExpiredToken, openMaps }
