<template>
  <button
    aria-label="open login"
    class="mr-4"
    v-if="isLoggedIn"
    @click="$emit('logout-click')"
  >
    <Badge :initials="initials" :isLoggedIn="this.isLoggedIn" />
    <!--     <div class="outer">
      <div
        class="inner"
        style="color: #fff;text-align: center;width: 34px; margin-top: 4px"
      >
        {{ initials }}
      </div>
      <font-awesome-icon
        icon="circle"
        class="text-3xl"
        style="width: 34px; height: 34px"
      />
    </div> -->
  </button>
</template>

<style scoped>
.inner {
  position: absolute;
}
</style>
<script>
import Badge from "./Badge"
export default {
  components: { Badge },
  name: "LoginBtn",
  props: {
    isLoggedIn: {
      required: true,
      type: Boolean,
    },
    initials: {
      required: true,
      type: String,
    },
  },

  mounted() {
    console.log("mounted")
  },
}
</script>
