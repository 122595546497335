import PrimeVue from "primevue/config"
import AutoComplete from "primevue/autocomplete";
import Calendar from "primevue/calendar"
import styles from "./styles"
import StyleClass from "primevue/styleclass"

const SetupPrimeVue = function(app) {
  app.directive("styleclass", StyleClass)
  app.component("AutoComplete", AutoComplete)
  app.component("Calendar", Calendar)
  app.use(PrimeVue, { pt: styles, unstyled: true })
}

export default SetupPrimeVue