<template>
    <button class="text-sm px-3" 
        type="button"
        v-on:click="$emit('link-clicked')">
        {{ label }}
    </button>
</template>

<script>
export default {
    name: "TextLink",
    props: ["label"],
}
</script>