<template>
  <ul class="metismenu overflow-y-auto" id="menu">
    <InternalMenuItem
      v-for="(subMenu, idx) in menuItem"
      :key="idx"
      :menuItem="subMenu"
      :menuArr="menuArr"
      v-on:click-action="$emit('click-action')"
    />
    <span class="text-lg text-opacity-75">
      <p class="quick-links px-4 py-3 settings-border">Quick Links</p>
    </span>
    <ExternalMenuItem
      v-for="(subMenu, idx) in menuItem"
      :key="idx + 100"
      :menuItem="subMenu"
      :menuArr="menuArr"
      v-on:click-action="$emit('click-action')"
    />
  </ul>
</template>

<style scoped>
.settings-border {
  border-bottom: solid 1px rgb(255, 255, 255, 0.4);
}

.quick-links {
  color: #fff;
  opacity: 0.65;
}

li.parent {
  border-bottom: solid 1px rgb(255, 255, 255, 0.3);
  font-size: 1.25rem;
}

a:hover {
  background: rgba(0, 0, 0, 0.2);
  color: white;
}

@media screen and (min-width: 1024px) {
  li.parent {
    font-size: 1rem;
  }
}
</style>
<script>
import ExternalMenuItem from "@/components/Menu/ExternalMenuItem"
import InternalMenuItem from "@/components/Menu/InternalMenuItem"

export default {
  components: { ExternalMenuItem, InternalMenuItem },
  name: "NavMenuItem",

  props: {
    menuItem: {
      required: true,
      type: Array,
    },
    menuArr: {
      required: true,
      type: Array,
    },
  },
}
</script>
