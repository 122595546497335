// This was created based off Microsoft's example:
// can be found at https://github.com/Azure-Samples/ms-identity-b2c-javascript-spa/tree/main/App

import * as msal from "@azure/msal-browser"
import {
  msalConfig,
  apiConfig,
  b2cPolicies,
  loginRequest,
  tokenRequest,
} from "./AuthServiceConfig"

export default class AuthService {
  constructor() {
    this.accountId
    this.username
    this.accessToken
    this.account
    this.fullname
    this.userLogged = false
    this.app = new msal.PublicClientApplication(msalConfig)
  }

  /**
   * Login user
   */
  login() {
    this.app.loginRedirect(loginRequest)
  }

  /**
   * Logout user
   */
  logout() {
    const logoutRequest = {
      postLogoutRedirectUri: window.location.href,
    }
    this.app.logoutRedirect(logoutRequest)
  }

  /**
   * Select account
   */
  selectAccount() {
    const currentAccounts = this.app.getAllAccounts()

    if (currentAccounts.length < 1) {
      this.userLogged = false
      return
    } else if (currentAccounts.length > 1) {
      const accounts = currentAccounts.filter(
        account =>
          account.homeAccountId
            .toUpperCase()
            .includes(b2cPolicies.names.signUpSignIn.toUpperCase()) &&
          account.idTokenClaims.iss
            .toUpperCase()
            .includes(b2cPolicies.authorityDomain.toUpperCase()) &&
          account.idTokenClaims.aud === msalConfig.auth.clientId
      )

      if (accounts.length > 1) {
        // localAccountId identifies the entity for which the token asserts information.
        if (
          accounts.every(
            account => account.localAccountId === accounts[0].localAccountId
          )
        ) {
          // All accounts belong to the same user
          this.setAccount(accounts[0])
          this.userLogged = true
        } else {
          // Multiple users detected. Logout all to be safe.
          this.logout()
        }
      } else if (accounts.length === 1) {
        this.setAccount(accounts[0])
        this.userLogged = true
      }
    } else if (currentAccounts.length === 1) {
      this.setAccount(currentAccounts[0])
      this.userLogged = true
    }
  }

  /**
   * is user logged in
   */
  isUserLogged() {
    return this.userLogged
  }

  /**
   * set account
   * @param { account } object with account info contained within
   */
  setAccount(account) {
    this.account = account
    this.accountId = account.homeAccountId
    this.username = account.username

    if (
      account.idTokenClaims.given_name == undefined ||
      account.idTokenClaims.family_name == undefined
    ) {
      this.fullName = ""
    } else {
      this.fullName =
        account.idTokenClaims.given_name +
        " " +
        account.idTokenClaims.family_name
    }


    this.userLogged = true
  }

  /**
   * get token redirect
   * @param { request } request object
   */
  getTokenRedirect(request) {
    request.account = this.app.getAccountByHomeId(this.accountId)

    return this.app
      .acquireTokenSilent(request)
      .then(response => {
        // console.log("response = " + JSON.stringify(response))
        // In case the response from B2C server has an empty accessToken field
        // throw an error to initiate token acquisition
        if (!response.accessToken || response.accessToken === "") {
          throw new msal.InteractionRequiredAuthError()
        } else {
          // console.log("access_token acquired at: " + new Date().toString())
          this.accessToken = response.accessToken
          this.passTokenToApi()
        }
      })
      .catch(error => {
        // console.log(
        // 	"Silent token acquisition fails. Acquiring token using popup. \n",
        // 	error
        // )
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return this.app.acquireTokenRedirect(request)
        } else {
          console.log(error)
        }
      })
  }

  /**
   * 	Acquires and access token and then passes it to the API call
   */
  passTokenToApi() {
    if (!this.accessToken) {
      this.getTokenRedirect(tokenRequest)
    } else {
      console.log(this.accessToken)
    }
  }

  async reauth() {
    var foo
    tokenRequest.account = this.app.getAccountByHomeId(this.accountId)

    return this.app
      .acquireTokenSilent(tokenRequest)
      .then(function(response) {
        // console.log("response = " + JSON.stringify(response))
        return response
      })
      .catch(function(error) {
        // console.log("reauth error = " + error)
      })
  }
}
