import gql from "graphql-tag"

export const getEventsByLocationDays = gql`
  query getEventsByLocationDays($locationName: String!) {
    getEventsByLocationDays(locationName: $locationName) {
      categories
      description
      location {
        address
        city
        description
        latitude
        longitude
        name
        phone
        tips
        type
        url
        zip
      }
      name
      occurrences {
        end
        start
        url
      }
      sortKey
      source
    }
  }
`

export const getLiveParking = gql`
  query getParking {
    getParking {
      address
      freeSpaces
      id
      lastUpdated
      latitude
      longitude
      name
      occupiedSpaces
      totalSpaces
      type
    }
  }
`

// export const getApps = gql`
//   query getApps {
//     entries(section: "mobileApps") {
//       ... on mobileApps_mobileApps_Entry {
//         id
//         title
//         windowsLink
//         webLink
//         iosLink
//         featuredApp
//         androidLink
//         body
//         mobileImage {
//           url
//         }
//       }
//     }
//   }
// `

export const getApps = gql`
  query MyQuery {
    mobileAppsEntries {
      ... on mobileApps_default_Entry {
        id
        title
        simpleRichText
        mobileAppPlatforms {
          ... on mobileAppPlatforms_BlockType {
            id
            android
            ios
            web
          }
        }
        mobileAppIcon {
          url
        }
      }
    }
  }
`

export const alerts = gql`
  query MyQuery {
    globalSets {
      ... on siteWideAlert_GlobalSet {
        alertBarIsVisible
        alerts {
          ... on alerts_BlockType {
            alertDescription
            alertSeverity
            alertTitle
            fontAwesomeIcon
            id
          }
        }
      }
    }
  }
`
