import { Preferences } from "@capacitor/preferences"

export default {
  /**
   * Get item from db
   * @param {string} key [the key to get]
   */
  async getItem(key) {
    const result = await Preferences.get({ key: key })
    return result.value
  },

  /**
   * Set item from db
   * @param {*} key
   * @param {*} data
   */
  async setItem(key, data) {
    await Preferences.set({
      key: key,
      value: data,
    })
  },

  /**
   * Clear db
   */
  async clearDb() {
    await Preferences.clear()
  },
}
