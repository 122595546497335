<template>
  <div
    class="bg-white m-auto rounded-lg content-center flex-none overflow-visible welcomeSreenBucket"
    v-bind:style="{ top: topSpacing }"
  >
    <div class="flex justify-between w-full mt-4">
      <div class="w-auto w-9/12">
        <h1 class="welcomeHdr ml-5 text-dark-blue-500 text-2xl font-semibold">VB Connect</h1>
      </div>
      <div class="w-auto w-3/12 text-right float-right pr-6 mt-1">
        <img src="@/assets/imgs/vb_logo.svg" width="36" class="float-right" alt="vb logo" />
      </div>
    </div>

    <div class="text-center h-12">
      <div onclick="" class="absolute px-5 w-full">
        <!-- TODO: Test on mobile -->
        <div
          class="flex flex-row justify-between py-2 content-center border rounded-md form-input form-input-container"
          style="border-color: #767676"
          id="address-input"
        >
          <button class="home-auto-suggest-buttons location-button" aria-label="locate me" v-on:click="getLocation()">
            <i class="fas fa-location-circle fa-lg -mt-6 text-gray-300"></i>
          </button>
          <AutoComplete
            ref="address"
            forceSelection
            v-model="message"
            :minLength="minSearchLength"
            :suggestions="addresses"
            @complete="onInputChange"
            @item-select="selectHandler"
            placeholder="Enter Your Address"
            :pt-options="{ mergeProps: true }"
            :pt="{
              input: { class: '!p-0 placeholder-gray-500' },
              root: { class: 'home-auto-suggest-root' },
              loadingicon: { class: 'hidden' },
            }"
            @keypress.enter="myvbEnterKey()"
            @keypress.enter.prevent="myvbEnterKey"
          />
          <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" :color="'#C53205'" :loader="'dots'" :opacity="0.8" :width="48"
          :height="48"></loading>
          <button
            class="home-auto-suggest-buttons clear-button"
            :class="{ 'hide-clear': !hasMessage }"
            aria-label="clear"
            v-on:click="clearAddressInput()"
          >
            <i class="fa fa-times-circle fa-lg -mt-6 text-gray-300"></i>
          </button>
        </div>
      </div>

      <div class="lds-ring mx-auto" :class="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="text-center pt-3">
      <Button
        v-if="this.$store.state.home.submitStatus.disabled === 1"
        label="SEARCH"
        :isDisabled="this.$store.state.home.submitStatus.disabled === 1"
        disabled
      />
      <Button
        v-if="this.$store.state.home.submitStatus.disabled !== 1"
        label="SEARCH"
        :isDisabled="this.$store.state.home.submitStatus.disabled === 1"
        v-on:btn-clicked="myvb()"
      />
      <br />
      <TextLink class="py-3" label="Skip" v-on:link-clicked="skip()" />
    </div>

    <p class="welcomeSubHdr px-5 text-lg font-semibold pb-4">
      Welcome! Find property information, services, and more related to your Virginia Beach address.
    </p>
  </div>
</template>

<script>
import router from "../router"
// import { VueAutosuggest } from "vue-autosuggest"
import store from "../store"
import { mapGetters } from "vuex"
import Button from "@/components/Button"
import TextLink from "@/components/TextLink"
import { Geolocation } from "@capacitor/geolocation"
import { Capacitor } from "@capacitor/core"
import { PushNotificationManager } from "@/utils/PushNotificationManager"
import Loading from 'vue-loading-overlay';
import "vue-loading-overlay/dist/css/index.css"
import { nextTick } from "vue"

export default {
  components: {
    //  VueAutosuggest,
    Button,
    TextLink,
    Loading
  },

  computed: {
    ...mapGetters("home", ["userAddress", "addresses", "details", "gpin", "loading", "error"]),
    hasMessage() {
      return this.message && this.message.length > 0
    },
    topSpacing() {
      if (this.$refs !== undefined) {
        if (Capacitor.getPlatform() === "ios") {
          return "calc(env(safe-area-inset-top) + 20px)"
        } else {
          return "20px"
        }
      }
      return ""
    },
  },

  /**
   * Watch for the change in address data for the loading indicator
   */
  watch: {
    addresses: function () {
      if (this.addresses.length > 0) {
        this.isLoading = false
      }
    },
    details: function () {
      this.isLoading = false
      router.push("/myVB")
    },
    error: function () {
      this.$toast.clear()
      this.$toast.open({
        message: this.$store.state.errorMessage,
        type: "error",
        position: "top",
      })
      this.isLoading = false
      store.commit("home/setError", false)
    },
    userAddress: function () {
      this.message = this.$store.state.home.userAddress
    },
  },
  methods: {
    myvb() {
      this.isLoading = true
      store.dispatch("home/getGpin", this.$store.state.home.userAddress)
    },

    myvbEnterKey() {
      if (this.$store.state.home.submitStatus.disabled != 1) {
        this.myvb()
      }
    },

    /**
     * Input change for text field
     */
    onInputChange(e) {
      const address = e.query
      if (address.length >= this.minSearchLength) {
        this.isLoading = true
        store.dispatch("home/getAddresses", address)
          .then(() => {
            this.isLoading = false 
          });
      } else {
        store.commit("home/resetAddresses")
        this.isLoading = false; 
      }
    },

    /**
     * Temp until we get the flow figured out
     */
    skip() {
      store.commit("home/setVerification", false)
      router.push("/news/newsReleases")
    },

    /**
     * Set users address
     * @param  {object} e [json object]
     */
    selectHandler(e) {
      if (e != null) {
        store.commit("home/setUserAddress", e.value)
        store.commit("home/setSubmitStatus", {
          disabled: 0,
          opacity: "opacity-100",
        })
      }
    },

    /**
     * Clear the address input
     */
    clearAddressInput() {
      Object.assign(this.$data, this.$options.data())
      store.commit("home/resetAddresses")
      store.commit("home/setUserAddress", "")
      store.commit("home/setSubmitStatus", {
        opacity: "opacity-25",
        disabled: 1,
      })
    },

    /**
     * Get my location
     */
    async getLocation() {
      try {
        this.isLoading = true
        const coordinates = await Geolocation.getCurrentPosition()
        store.dispatch("home/getAddress", {
          lat: coordinates.coords.latitude,
          long: coordinates.coords.longitude,
        })
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        this.$toast.clear()
        this.$toast.open({
          message: "Error getting your location. Please check your settings.",
          type: "error",
          position: "top",
        })
      }
    },
  },

  /**
   * Data return
   * @return {[message: ""} [reset message]
   */
  data: function () {
    return {
      deviceInfo: undefined,
      message: "",
      minSearchLength: 5,
      isLoading: false 
    }
  },

  /**
   * Page has mounted
   */
  async mounted() {
    this.isLoading = false
    store.commit("home/setVerification", false)
    this.clearAddressInput()

    store.commit("home/resetDb")
    localStorage.clear()

    if (Capacitor.isNativePlatform()) {
      PushNotificationManager.register()
    }
    nextTick().then(() => {
      this.$refs.address.$el.children[0].focus()
    })
  },
}
</script>
<style lang="scss">
.home-auto-suggest-root {
  flex-grow: 3;
  input {
    width: 100%;
  }
}

.home-auto-suggest-buttons {
  flex-grow: 0;
}

.form-input-container {
  padding-left: 5px !important;
  padding-right: 5px !important;
  .location-button {
    margin-right: 5px;
  }
  border: 4px solid red;
}

.hide-clear {
  display: none;
}

.ui-autocomplete-panel {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.v-toast--top {
  top: 70px !important;
}
</style>
