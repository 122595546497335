import Vue from 'vue'
import axios from "axios"
import VueAxios from 'vue-axios'
import qs from "qs" 

// Vue.use(VueAxios, axios)
export default {
  /**
   * Get
   * @param  {string} url     [url to get with]
   * @param  {object} request [request]
   * @return {object}         [data or an error]
   */
  get(url, request) {
    return axios
      .get(url, request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  post(url, request, headers) {
    return axios
      .post(url, request, { headers: headers })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  request(url, options={method:'get'}) {
    const types = ['post','get', 'put', 'patch', 'delete'];
    if(!types.includes(options.method)) throw `The type parameter must be ${types.join(', ')}`;
    const opts = {url:url,
                  method:options.method,
                  headers:options.headers || {}};
    
    options.query && (opts.data = qs.stringify(options.query)); 
    
    console.log(opts)
    // return new Promise(data => {return data});
    return axios(opts)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
}
