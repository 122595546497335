import api from "../utils/api.js";

const Navigation = {
    namespaced: true,

    state: {
        navigationItems: [],
    },

    getters: {
        /**
         * @returns navigation items
         */
        navigationItems: (state) => {
            return state.navigationItems;
        },
    },

    actions: {
        /**
         * Fetches navigation items from web service
         *
         * @param {*} { commit }
         */
        async loadNavigation({ commit, state }) {
            const response = await api.get(process.env.VUE_APP_NAVIGATION_URL);

            const data = await response.data.data;

            if (data !== undefined && data !== null) {
                // TODO - delete this
                data.forEach((item) => {
                    if (item.name === "News") {
                        item.url = "";
                    }
                });
                // ---

                data.sort((a, b) => {
                    return a.order - b.order;
                });
            }

            commit("setNavigationItems", data);
        },
    },

    mutations: {
        /**
         * Sets the navigation items
         *
         * @param {*} state - current state
         * @param {*} data - new navigation items
         */
        setNavigationItems(state, data) {
            state.navigationItems = data;
        },
    },
};

export default Navigation;
