<template>
  <nav id="top-navigation" class="relative" role="navigation">
    <div class="shadow top_nav grid">
      <div>
        <button
          v-if="!isRoot"
          @click="navigateTo(verified ? '/myvb' : '/news/all')"
          class="vb_logo pl-2"
          aria-label="home"
        >
          <img src="@/assets/imgs/vb_logo.svg" alt="City of Virginia Beach logo" width="42" aria-hidden="true" />
        </button>
      </div>

      <span class="text-2xl">
        <p
          class="top_nav_hdr font-semibold text-center text-dark-blue-500"
          v-bind:aria-label="pageTitle == 'MyVB' ? 'My V B' : pageTitle"
        >
          {{ pageTitle }}
        </p>
      </span>

      <div
        class="text-right text-dark-blue-500 top_nav_menu_icon grid grid-cols-2"
        style="grid-template-columns: calc(100% - 30px) 30px"
      >
        <div>
          <LoginBtn :isLoggedIn="this.isLoggedIn" @login-click="login()" v-if="!isRunningInProd" />

          <Badge :isLoggedIn="this.isLoggedIn" :initials="initials" v-if="!isRunningInProd" />
        </div>

        <div>
          <button v-if="!this.isMenuShown" @click="toggleMenu()" ref="menuBtn" aria-label="open menu">
            <font-awesome-icon icon="bars" class="text-3xl" />
          </button>

          <button v-if="this.isMenuShown" @click="toggleMenu()" ref="menuBtn" aria-label="open menu">
            <font-awesome-icon icon="times" class="text-3xl" />
          </button>
        </div>
      </div>
    </div>

    <button
      v-if="isBackgroundBtnShown()"
      @click="toggleMenu()"
      tabindex="-1"
      ref="hideMenuBtn"
      aria-label="close menu"
      class="secretButton fixed custom-top-nav-shadow right-0 bottom-0 left-0 h-full w-full cursor-default bg-black bg-opacity-50"
      :class="getMenuBGAction"
    ></button>

    <div
      v-if="this.isMenuShown"
      class="absolute overflow-y-auto menuContainer right-0 pb-10"
      :class="getMenuAction"
      ref="menuContainer"
    >
      <div
        class="flex flex-row-reverse settings-border settings-opacity grid grid-cols-2"
        style="grid-template-columns: 50% 50%"
      >
        <div style="grid-template-columns: 40px calc(80% - 40px)" class="grid grid-cols-2 ml-2.5 mt-2 h-4/5">
          <div>
            <LogoutBtn :isLoggedIn="this.isLoggedIn" :initials="initials" @logout-click="logout()" />
          </div>

          <div class="mt-1" v-if="isLoggedIn" v-on:click="logout()">Logout</div>
        </div>

        <div class="text-right mt-1">
          <button class="px-4 py-2 text-white" @click="navigateTo('/settings')">
            <i class="fa fa-cog" ref="setting-icon" alt="settings" />

            <span class="text-base">
              <p class="inline">&nbsp; Settings</p>
            </span>
          </button>
        </div>
      </div>

      <NavMenuItem :menuItem="getNoChildren" :menuArr="navigationItems" v-on:click-action="handleMenuClicked()" />
    </div>
  </nav>
</template>

<style scoped>
#top-navigation {
  z-index: 2000;
}

#secretButton {
  z-index: 1500;
}
.settings-opacity {
  opacity: 0.75;
}
.settings-border {
  border-bottom: solid 1px rgb(255, 255, 255, 0.7);
}
@keyframes open-menu {
  from {
    right: -25rem;
  }
  to {
    right: 0px;
  }
}

@keyframes close-menu {
  from {
    right: 0px;
  }
  to {
    right: -25rem;
  }
}

.menuContainer {
  display: grid;
  grid-template-rows: 50px auto;
  background-image: linear-gradient(#0b73a3, #6eb59a);
  height: calc(100vh - 60px - env(safe-area-inset-top));
  width: 100%;
}

@media screen and (min-width: 64em) {
  .menuContainer {
    width: 25rem;
  }
}

.animateOpen {
  animation: open-menu;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.animateClose {
  animation: close-menu;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.animateAppear {
  animation: bg-appear;
  animation-duration: 0.5s;
}

.animateDisappear {
  animation: bg-disappear;
  animation-duration: 0.4s;
}

.custom-top-nav-shadow {
  top: 60px;
}
</style>

<script>
import router from "../router"
import store from "../store"
import { mapGetters } from "vuex"

import NavMenuItem from "@/components/Menu/NavMenuItem"
import LoginBtn from "@/components/Login/LoginBtn"
import LogoutBtn from "@/components/Login/LogoutBtn"
import Badge from "@/components/Login/Badge"
import MetisMenu from "metismenujs"
import { Capacitor } from "@capacitor/core"

import { msalConfig } from "@/services/AuthServiceConfig"
import { checkForExpiredToken } from "../utils/misc.js"

import Azureb2cplugin from "@/utils/pluginConnect"

export default {
  components: { NavMenuItem, LoginBtn, LogoutBtn, Badge },
  props: ["pageTitle"],

  computed: {
    ...mapGetters("auth", ["getAuthenticated", "getInitials", "getAuthResponse"]),
    ...mapGetters("navigation", ["navigationItems"]),
    ...mapGetters("home", ["userAddress", "verified"]),

    /**
     * filter menu data list to exclude children menu items
     */
    getNoChildren() {
      let dataList = this.navigationItems.filter((item) => item.isChild !== true)

      if (!this.verified) {
        dataList = dataList.filter((item) => !item.requireLogin)
      }

      return dataList
    },
    /**
     * attach custom CSS for menu action
     */
    getMenuAction() {
      if (this.menuActionState) {
        return "animateOpen"
      } else {
        return "animateClose"
      }
    },
    /**
     * attach custom CSS for screen background on menu action
     */
    getMenuBGAction() {
      if (this.menuActionState) {
        return "animateAppear"
      } else {
        return "animateDisappear"
      }
    },

    isNative: function () {
      return Capacitor.isNativePlatform()
    },

    /**
     * Checks to see if the app is running in production
     */
    isRunningInProd: function () {
      return process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test"
    },
  },
  data() {
    return {
      isMenuShown: false,
      menuActionState: false,
      isRoot: false,
      isLoggedIn: false,
      initials: "",
      inAppBrowserRef: undefined,
      closeInAppBrowserFlag: false,
    }
  },

  /**
   * Watch for the change in the verified boolean
   */
  watch: {
    /**
     * Watch for changes to the route
     */
    $route: function (to, from) {
      this.isRoot = this.$router.currentRoute.name == "MyVB"
    },

    /**
     * Watch for changes to the route
     */
    getAuthenticated(newValue, oldValue) {
      this.isLoggedIn = newValue
    },

    getInitials(newValue, oldValue) {
      this.initials = newValue
    },
  },

  methods: {
    /**
     * Navigiate to the provided path
     * @param  {string} path [path to use to navigate to]
     */
    navigateTo(path) {
      if (this.$route.path != path) {
        router.push({ path: path })
      }

      if (this.isMenuShown) {
        this.toggleMenu()
      }
    },
    /**
     * Close InAppBrowser
     */
    closeInAppBrowser() {
      this.inAppBrowserRef.hide()
    },

    /**
     * Message callback from InAppBrowser
     * @param  {string} params [data coming back from InAppBrowser ]
     */
    messageCallBack(params) {
      if (params.data.statusCode === 500) {
        this.closeInAppBrowser()
        this.closeInAppBrowserFlag = false
        this.$toast.clear()
        this.$toast.open({
          message: params.data.message,
          type: "error",
          position: "top",
        })
      }

      //comment out below to test toast error message
      if (Capacitor.isNativePlatform()) {
        if (params.data.message === "Logout") {
          this.closeInAppBrowser()
          this.closeInAppBrowserFlag = false
          return
        }

        try {
          if (params.data.my_message.account.localAccountId != undefined) {
            this.closeInAppBrowser()
            this.closeInAppBrowserFlag = false
          }
        } catch (error) {
          // console.log("error = " + error)
        }

        store.dispatch("auth/handleResponse", params.data.my_message)
      }
    },

    /**
     * Load stop happens when each page in the InAppBrowser is loaded
     * @param  {string} params [data coming back from InAppBrowser ]
     */
    loadStopCallBack(params) {
      // if we have gone to these screens, then set the close flag
      if (params.url.includes("ForgotPassword") || params.url.includes("CombinedSigninAndSignup")) {
        this.closeInAppBrowserFlag = true
      }

      // if the Cancel button is hit, then your url will include this and you have Cancelled the forgot password or sign up screen
      if (this.closeInAppBrowserFlag == true && params.url.includes(msalConfig.auth.redirectUri)) {
        this.closeInAppBrowser()
        this.closeInAppBrowserFlag = false
      }
    },

    /**
     * Exit the InAppBrowser
     */
    exitInAppBrowser() {
      this.closeInAppBrowserFlag = false
    },

    /**
     * Login
     */
    async login() {
      if (Capacitor.isNativePlatform()) {
        try {
          const { token } = await Azureb2cplugin.openLogin()
        } catch (e) {
          console.log("error = " + e)
        }
      } else {
        store.dispatch("auth/login")
      }
    },

    /**
     * Logout
     */
    async logout() {
      if (Capacitor.isNativePlatform()) {
        await Azureb2cplugin.logout()
      } else {
        store.dispatch("auth/logout")
      }

      this.toggleMenu()
    },

    /**
     * toggle menu
     */
    toggleMenu() {
      this.menuActionState = !this.menuActionState

      setTimeout(() => {
        this.isMenuShown = this.menuActionState
      }, 375)
    },
    /**
     * keydown event listener
     */
    handleEscape(e) {
      if (this.isMenuShown && (e.key === "Esc" || e.key === "Escape")) {
        this.toggleMenu()
      }
    },
    /**
     * window resize listener
     */
    handleResize(e) {
      new MetisMenu("#menu")
    },

    /**
     * determines if background menu button is shown
     */
    isBackgroundBtnShown() {
      let isShown = false

      if (this.isMenuShown) {
        isShown = !Capacitor.isNativePlatform()
      }

      return isShown
    },

    /**
     * click event attached to menu
     */
    handleMenuClicked() {
      this.toggleMenu()
    },

    /**
     * on resume of ios and android app, check for expired token
     */
    onResume() {
      try {
        if (checkForExpiredToken(store.state.auth.authResponse.idTokenClaims.exp) == true) {
          // console.log("check ==== EXPIRED")
          store.dispatch("auth/clearAll")
        } else {
          // console.log("check ==== NOT EXPIRED")
        }
      } catch (error) {
        // console.log("error = " + error)
      }
    },
  },

  // ------------------
  // VueJS Lifecycle Hooks
  // ------------------
  created() {
    document.addEventListener("keydown", this.handleEscape)
    document.addEventListener("resize", this.handleResize)
    if (Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android") {
      document.addEventListener("resume", this.onResume)
    }
  },
  async mounted() {
    try {
      await store.dispatch("navigation/loadNavigation")
    } catch (e) {
      store.dispatch("navigation/loadNavigation") // try one more time
    }

    if (this.userAddress) {
      store.commit("home/setVerification", true)
    }
  },
  async updated() {
    // -- re-initialize metismenu if enabled
    if (this.isMenuShown) {
      new MetisMenu("#menu")
    }

    // --
  },
  destroyed() {
    document.removeEventListener("keydown", this.handleEscape)
    document.removeEventListener("resize", this.handleResize)

    if (Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android") {
      document.removeEventListener("resume", this.onResume)
    }
  },
}
</script>
