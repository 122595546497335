import { createApp } from "vue"
import VueMeta from "vue-meta"
import Vuex from "vuex"
import ToastPlugin from "vue-toast-notification"
import "vue-toast-notification/dist/theme-default.css"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import "./assets/css/tailwind.css"
import "./assets/css/loadingIndicator.css"
import "./assets/css/leaflet.css"
import "./assets/css/fontFace.css"
import SetupPrimeVue from "../primevue/primevue.setup.js"
import storage from "./utils/storage.js"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library, dom } from "@fortawesome/fontawesome-svg-core"

//free-solid-svg-icons
import { faNewspaper } from "@fortawesome/free-solid-svg-icons/faNewspaper"
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope"
import { faIdCard } from "@fortawesome/free-solid-svg-icons/faIdCard"
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle"
import { faShareAlt } from "@fortawesome/free-solid-svg-icons/faShareAlt"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft"
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog"
import { faSnowflake } from "@fortawesome/free-solid-svg-icons/faSnowflake"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle"
import { faTv } from "@fortawesome/free-solid-svg-icons/faTv"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle"
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup"
import { faSwimmingPool } from "@fortawesome/free-solid-svg-icons/faSwimmingPool"
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars"
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink"
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes"
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar"
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle"
import { faUserCheck } from "@fortawesome/free-solid-svg-icons/faUserCheck"
import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle"

//free-brands-svg-icons
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter"
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube"
import { faAppStoreIos } from "@fortawesome/free-brands-svg-icons/faAppStoreIos"
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons/faGooglePlay"
import { faYelp } from "@fortawesome/free-brands-svg-icons/faYelp"

//free-brands-svg-icons
import { faBooks } from "@fortawesome/pro-regular-svg-icons/faBooks"
import { faFileChartPie } from "@fortawesome/pro-regular-svg-icons/faFileChartPie"
import { faTint } from "@fortawesome/pro-regular-svg-icons/faTint"
import { faHouse } from "@fortawesome/pro-regular-svg-icons/faHouse"
import { faIdCardAlt } from "@fortawesome/pro-regular-svg-icons/faIdCardAlt"
import { faDumpster } from "@fortawesome/pro-regular-svg-icons/faDumpster"
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash"
import { faGavel } from "@fortawesome/pro-regular-svg-icons/faGavel"
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons/faExternalLink"
import { faExternalLinkSquare } from "@fortawesome/pro-regular-svg-icons/faExternalLinkSquare"

//pro-duotone-svg-icons
import { faMapMarkerAlt } from "@fortawesome/pro-duotone-svg-icons/faMapMarkerAlt"
import { faBrowser } from "@fortawesome/pro-duotone-svg-icons/faBrowser"
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons/faInfoCircle"
import { faBuilding } from "@fortawesome/pro-duotone-svg-icons/faBuilding"
import { faBoxBallot } from "@fortawesome/pro-duotone-svg-icons/faBoxBallot"

//pro-solid-svg-icons
import { faHurricane } from "@fortawesome/pro-solid-svg-icons/faHurricane"
import { faLocationCircle } from "@fortawesome/pro-solid-svg-icons/faLocationCircle"

import VueMasonry from "vue-masonry-css"

// import VeeValidate from "vee-validate"
import Maska from "maska"
import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"
import { LoadingPlugin } from "vue-loading-overlay"
import "vue-loading-overlay/dist/css/index.css"

import SmartBanner from "smart-app-banner"
import "smart-app-banner/dist/smart-app-banner.css"
import { Capacitor } from "@capacitor/core"
import { VueReCaptcha } from 'vue-recaptcha-v3';

library.add(
  faCalendar,
  faNewspaper,
  faPhone,
  faEnvelope,
  faIdCard,
  faVideo,
  faTimesCircle,
  faShareAlt,
  faExternalLinkAlt,
  faTwitter,
  faChevronRight,
  faChevronDown,
  faYoutube,
  faChevronLeft,
  faCog,
  faBooks,
  faFileChartPie,
  faTint,
  faHouse,
  faIdCardAlt,
  faDumpster,
  faTrash,
  faGavel,
  faSwimmingPool,
  faSnowflake,
  faExclamationTriangle,
  faTv,
  faQuestionCircle,
  faLayerGroup,
  faAppStoreIos,
  faGooglePlay,
  faBrowser,
  faInfoCircle,
  faBars,
  faBuilding,
  faLink,
  faBoxBallot,
  faExternalLink,
  faExternalLinkSquare,
  faTimes,
  faHurricane,
  faYelp,
  faMapMarkerAlt,
  faLocationCircle,
  faUserCircle,
  faUserCheck,
  faCircle
)

dom.watch()

const app = createApp({
  extends: App,
  beforeCreate() {
    this.$store.commit("initialiseStore")
  },
  created() {
    if (!Capacitor.isNativePlatform()) {
      new SmartBanner({
        daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        title: "VB Connect",
        author: "City of Virginia Beach",
        button: "VIEW",
        store: {
          ios: "On the App Store",
          android: "In Google Play",
        },
        price: {
          ios: "FREE",
          android: "FREE",
        },
        // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        // , icon: '' // full path to icon image if not using website icon image
      })
    }
  },
})

SetupPrimeVue(app)
app.use(VueMasonry)
app.use(ToastPlugin)
app.use(router)
app.use(store)
app.use(Vuex)
// Vue.use(Auth)
// Vue.use(VeeValidate)
app.use(Maska)
app.use(LoadingPlugin)

app.component("font-awesome-icon", FontAwesomeIcon)
app.component("v-select", vSelect)
app.mount("#app")
app.use(VueReCaptcha, {
    siteKey: '6LfXQZ4pAAAAAEUEcvAeVAVSh73Ke164cPS-IGEv',
});
store.subscribe(async (mutation, state) => {
  storage.setItem("store", JSON.stringify(state))
})
