<template>
    <button class="rounded-full px-7 py-1 text-white" 
        :class="checkIsDisabled" 
        type="button"
        v-on:click="$emit('btn-clicked')">
        <img v-if="imgSrc" :src="imgSrc" :alt="altText" class="inline h-6 w-6">
        {{ label }}
    </button>
</template>

<script>
export default {
    name: "Button",
    props: ["label", "isDisabled", "imgSrc", "altText"],
    computed: {
        /**
         * assigns redness based on property 'isDisabled'
         */
        checkIsDisabled() {
            if (this.isDisabled) {
                return "bg-red-100";
            } else {
                return "bg-red-600";
            }
        }
    }
}
</script>