// src/store/services/index.js
import api from "../utils/api.js";

/**
 * City services
 */
const Services = {
    namespaced: true,

    state: {
        dataList: [],
    },

    mutations: {
        /**
         * Sets the data list
         *
         * @param {*} state - current state
         * @param {*} data - new data list
         */
        setDataList(state, data) {
            state.dataList = data;
        },
    },

    actions: {
        /**
         * Fetches items from web service
         *
         * @param {*} { commit }
         */
        async loadServices({ commit }) {
            const response = await api.get(process.env.VUE_APP_SERVICES_URL);

            const data = await response.data.data;

            commit("setDataList", data);
        },
    },

    getters: {
        /**
         * Returns city service data
         *
         * @param {*} state - current state
         */
        serviceItems: (state) => {
            return state.dataList;
        },
    },
};

export default Services;
