import axios from "axios"

const News = {
  namespaced: true,
  state: {
    newsItems: [],
  },

  getters: {
    /**
     * Gets news items of a particular type, if no type is specified or if "all" is
     * specified then return all news items regardless of type
     *
     * @param {String} type - the type of news items to fetch
     * @returns {Array} of news items matching the requested type
     */
    newsItems: state => type => {
      if (type != null && type != "all") {
        return state.newsItems.filter(newsItem => {
          return newsItem.type == type
        })
      }
      return state.newsItems
    },
  },

  actions: {
    /**
     * Fetches news items from the remote web service
     *
     * @param {*} { commit }
     */
    async loadNews({ commit }) {
      const response = await axios.get(process.env.VUE_APP_NEWS_URL)
      const data = await response.data.data

      commit("setNewsItems", data)
    },
  },

  mutations: {
    /**
     * Save news items to state
     *
     * @param {*} state
     * @param {*} data
     */
    setNewsItems(state, data) {
      state.newsItems = data
    },
  },
}

export default News
