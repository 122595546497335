<template>
  <li :class="updateBorderClass" v-if="!menuItem.isExternal">
    <router-link
      :to="menuItem.url"
      :class="anchorClassObj"
      class="flex justify-between text-white px-4 py-3"
      @click.native="handleClicked()"
    >
      <p class="inline">{{ menuItem.name }}</p>
      <i v-if="menuItem.isParent" class="fas fa-chevron-right float-right mt-1"></i>
    </router-link>
    <ul v-if="menuItem.isParent">
      <InternalMenuItem
        v-for="(subMenu, idx) in getChildren"
        :key="menuItem.name + idx"
        :menuItem="subMenu"
        :menuArr="menuArr"
        v-on:click-action="$emit('click-action')"
      />
    </ul>
  </li>
</template>

<style scoped>
li.child {
  border-bottom: solid 1px #d9efe6;
}

li.child:last-child {
  border: 0;
}
a:hover {
  background: rgba(0, 0, 0, 0.2);
  color: white;
}

@keyframes animateOpen {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

@keyframes animateClose {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
}

a.expand svg {
  animation: animateOpen;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

a.contract svg {
  animation: animateClose;
  animation-duration: 0.25s;
  animation-timing-function: linear;
}
</style>
<script>
import { Capacitor } from "@capacitor/core"
import { Device } from "@capacitor/device"
import router from "../../router"

export default {
  name: "InternalMenuItem",
  data() {
    return {
      isExpanded: false,
    }
  },
  props: {
    menuItem: {
      required: true,
      type: Object,
    },
    menuArr: {
      required: true,
      type: Array,
    },
  },
  computed: {
    /**
     * update anchor tag based on a variety of parameter
     */
    anchorClassObj: function () {
      return {
        "bg-white": this.menuItem.isChild,
        "text-light-blue-500": this.menuItem.isChild,
        "pl-8": this.menuItem.isChild,
        expand: this.menuItem.isParent && this.isExpanded,
        contract: this.menuItem.isParent && !this.isExpanded,
      }
    },
    /**
     * update border color based on if menuItem is child
     */
    updateBorderClass() {
      if (this.menuItem.isChild) {
        return "child"
      } else {
        return "parent"
      }
    },
    /**
     * get children menu items that match the selected menuItem
     */
    getChildren() {
      return this.menuArr.filter((item) => item.parent === this.menuItem.name)
    },
  },
  methods: {
    /**
     * menu item clicked listener
     */
    handleClicked() {
      if (this.menuItem.isParent) {
        this.isExpanded = !this.isExpanded
      } else {
        this.$emit("click-action")
      }
    },
  },
}
</script>
