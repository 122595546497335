// src/store/settings/index.js
import api from "../utils/api.js"

/**
 * City services
 */
const Setting = {
  namespaced: true,

  state: {
    version: undefined,
  },

  actions: {
    /**
     * Fetches items from web service
     *
     * @param {*} { commit }
     */
    async loadVersion({ commit }) {
      try {
        const response = await api.get(process.env.VUE_APP_SETTINGS_URL)
        const data = await response.data
        commit("setVersion", data.data)
      } catch (e) {
        //console.log("e = " + e)
      }
    },
  },
  mutations: {
    /**
     * Sets the data list
     *
     * @param {*} state - current state
     * @param {*} data - new data list
     */
    setVersion(state, data) {
      state.version = data[0].version
    },
  },
  getters: {
    /**
     * Returns city service data
     *
     * @param {*} state - current state
     */
    getVersion: state => {
      return state.version
    },
  },
}

export default Setting
