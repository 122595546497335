import Vue from "vue"
import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
import store from "@/store"

import { App } from "@capacitor/app"
import { Capacitor } from "@capacitor/core"
import { Browser } from "@capacitor/browser"

// Vue.use(VueRouter)

/**
 * Checks to see if a user address has been set and if so skip Home and go straight to myVB
 *
 * @param {*} to
 * @param {*} from
 * @param {*} next
 */
export async function checkForAddress(to, from, next) {
  if (to.query.action && to.query.action === "reset") {
    next()
  } else {
    const userAddress = await store.getters["home/userAddress"]
    const userDetails = await store.getters["home/details"]

    if (
      userAddress &&
      userAddress.length > 0 &&
      userDetails !== undefined &&
      userDetails !== null
    ) {
      next("/myvb")
    } else {
      next()
    }
  }
}

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: checkForAddress,
    meta: {
      title: "Virginia Beach Property Search and City Services",
      description: "VB Connect offers comprehensive property management and city services in Virginia Beach. Manage your property details and access essential information."
    }
  },
  {
    path: "/myvb",
    name: "MyVB",
    component: () => import(/* webpackChunkName: "myvb" */ "../views/MyVB.vue"),
    beforeEnter: async (to, from, next) => {
      const userAddress = await store.getters["home/userAddress"]
      if (to.name === "MyVB" && (!userAddress || userAddress.length === 0)) next({ name: "Home" })
      else next()
    },
    meta: {
      title: "Virginia Beach Property Information, Waste Management & Local Services",
      description: "Explore property info, waste management, and local services for your Virginia Beach address. Get notifications for garbage day and stay updated."
    }
  },
  {
    path: "/events",
    name: "Events",
    component: () => import(/* webpackChunkName: 'events' */ "@/views/Events.vue"),
    meta: {
      title: "Explore Events in Virginia Beach | Local Activities & More",
      description: "Discover and explore upcoming events and activities in Virginia Beach. Stay informed about local events happening in your community."
    }

  },
  {
    path: "/help",
    name: "HELP Q&A  ",
    component: () => import(/* webpackChunkName: "services" */ "../views/Help.vue"),
    meta: {
      title: "VB Connect Mobile App Help & Troubleshooting | Support",
      description: "Find solutions and support for the VB Connect app. Access local services, property info, emergency updates, and more for Virginia Beach residents."
    }
  },
  {
    path: "/terms",
    name: "T.O.S.",
    component: () => import(/* webpackChunkName: "services" */ "../views/Terms.vue"),
    meta: {
      title: "VB Connect Terms, Privacy Policy & Info Security | VB",
      description: "Explore terms, privacy policy, and information security for the VB Connect website. Learn about services and policies for residents and visitors."
    }
  },
  {
    path: "/services",
    name: "Services",
    component: () => import(/* webpackChunkName: "services" */ "../views/Services.vue"),
    meta: {
      title: "Property Info, Library, Police, Water Bill & More | Services",
      description: "Access city services like property info, library services, police reports, water bill payments, employment, curbside services, parks & rec, bulk item pickup, and jury duty."
    }
  },
  {
    path: "/news/:tab",
    name: "News",
    props: true,
    component: () => import(/* webpackChunkName: "news" */ "../views/News.vue"),
    meta: {
      title: "Virginia Beach News | Latest Updates, Events & More",
      description: "Stay informed with the latest news from Virginia Beach. Get breaking news, police reports, weather updates, and more. Explore community events and city updates."
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import(/* webpackChunkName: 'settings' */ "@/views/Settings.vue"),
    meta: {
      title: "VB Connect Settings | Virginia Beach Info & User Config",
      description: "Manage VB Connect settings for Virginia Beach. Access info services, change address, submit feedback, and explore the official city website and other apps."
    }
  },
  {
    path: "/apps",
    name: "Apps",
    component: () => import(/* webpackChunkName: 'apps' */ "@/views/Apps.vue"),
    meta: {
      title: "Virginia Beach Mobile Apps | City Services, Information & More",
      description: "Discover Virginia Beach mobile apps for city services, beach info, social services, and more. Access VB Connect, park finder, library catalog, parking meters, and community apps."
    }
  },
  {
    path: "/vb-oceanfront-parking",
    name: "Parking",
    component: () => import(/* webpackChunkName: 'apps' */ "@/views/Map.vue"),
    meta: {
      title: "Virginia Beach Live Parking Map | Find Parking Spots & Garages",
      description: "Discover available parking spots in Virginia Beach with our live parking map. Access real-time data on street parking, parking lots, garages, and parking spaces"
    }
  },
  {
    path: "/library-registration",
    name: "Library",
    component: () => import(/* webpackChunkName: 'apps' */ "@/views/LibraryRegistration.vue"),
    meta: {
      title: "Virginia Beach Library Registration | Create Your Library Account",
      description: "Register for a Virginia Beach Public Library account to access various library services. Complete the form for different account types and enjoy library resources."
    }
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import(/*webpackChunkName: '404' */ "@/views/Error404.vue"),
    meta: {
      title: "404 Error - Page Not Found | VB Connect App",
      description: "Oops! The page you're looking for can't be found on the VB Connect App. Please check your URL or return to the homepage. Ensure your app is updated for the best experience"
    }
  },
]

const router = new createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  // Update meta description provided in the meta object inside each route
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", to.meta.description);
  }
  next();
});

// added to correct behavior when hardware backbutton is pressed for android devices
/* istanbul ignore next */
if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "android") {
  App.addListener("backButton", () => {
    const path = window.location.pathname

    if (path === "/" || path === "/myvb") {
      App.exitApp()
    } else {
      router.back()
    }
  })
}

export default router
