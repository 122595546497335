import axios from "axios"

/**
 * Events
 */
const Events = {
  namespaced: true,

  state: () => ({
    eventsName: "",
    eventsLibrary: [],
    eventsPark: [],
    eventsParkRec: [],
    eventsMeta: [],
  }),

  getters: {
    eventsName: state => {
      return state.eventsName
    },
    eventsMeta: state => {
      return state.eventsMeta
    },
    eventsLibrary: state => {
      return state.eventsLibrary
    },
    eventsPark: state => {
      return state.eventsPark
    },
    eventsParkRec: state => {
      return state.eventsParkRec
    },
  },

  actions: {
    /**
     * Fetches items from web service
     * @param {*} param0
     */
    async getEventsByLocationDays({ commit }, params) {
      let postData = { params }

      const response = await axios.post(process.env.VUE_APP_MYVB_EVENTS_URL, postData, {
        headers: { "Content-Type": "application/json" },
      })

      commit("setEvents", {
        data: response.data.data.getEventsByLocationDays,
        nameOfLocationObj: params.nameOfLocationObj,
      })
    },
  },

  mutations: {
    /**
     * sets the last update data
     * @param {*} state
     * @param {*} data
     */
    setEvents(state, params) {
      state[params.nameOfLocationObj] = params.data
    },

    /**
     * sets the events name
     * @param {*} state
     * @param {*} data
     */
    setEventsName(state, data) {
      state.eventsName = data
    },

    /**
     * sets the events meta
     * @param {*} state
     * @param {*} data
     */
    setEventsMeta(state, data) {
      var newData = []

      const MAX_LEN = 50
      for (var i = 0; i < data.length; i++) {
        // truncate long names
        let name = data[i].name
        let shortName = name

        if (name.length > MAX_LEN) {
          if (name.charAt(MAX_LEN) === " ") {
            shortName = name.substring(0, MAX_LEN)
          } else {
            let idx = name.lastIndexOf(" ", MAX_LEN)
            shortName = `${name.substring(0, idx)} ...`
          }
        }
        data[i].shortName = shortName

        // break out multiple occurrences
        if (data[i].occurrences.length > 1) {
          for (var j = 0; j < data[i].occurrences.length; j++) {
            var newObject = {}
            newObject = JSON.parse(JSON.stringify(data[i]))
            newObject.occurrences = [data[i].occurrences[j]]
            newData.push(newObject)
          }
        } else {
          newData.push(data[i])
        }
      }

      state.eventsMeta = newData.sort(function(a, b) {
        return new Date(a.occurrences[0].start) - new Date(b.occurrences[0].start)
      })
    },
  },
}

export default Events
