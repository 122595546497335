import api from "../utils/api.js"
import { createPropertyBoundsQuery } from "../utils/esriQuery.js"

const Address = {
  namespaced: true,

  state: {
    address: "",
    addressPolygon: [],
    showInfoButton: true,
    error: false,
  },

  getters: {
    /**
     * Get the error
     * @param {*} state - current app state
     * @return {boolean}- true or false
     */
    error: state => {
      return state.error
    },
    /**
     * Get the address polygon
     * @param {*} state - current app state
     * @return {array} - array of points for the address
     */
    addressPolygon: state => {
      return state.addressPolygon
    },

    /**
     * Get the lat and lng
     * @param {*} state - current app state
     * @return {object} - lat and lng object
     */
    address: state => {
      return state.address
    },

    /**
     * Get show Info button data
     * @param {*} state - current app state
     * @return {boolean} - button state
     */
    showInfoButton: state => {
      return state.showInfoButton
    },
  },

  actions: {
    /**
     * Get the esri details
     * @param  {function} options.commit [commit function]
     * @param  {string} param          [details lookup]
     */
    async getEsriInfoForAddress({ commit }, param) {
      var url =
        "https://geo.vbgov.com/mapservices/rest/services/Basemaps/Property_Information/MapServer/12/query?&returnGeometry=true&outSR=4326&f=json&where=" +
        createPropertyBoundsQuery(param)

      try {
        var response = await api.get(url)
        commit("setAddressPolygon", response["data"])
      } catch (e) {
        this.commit("address/setError", true)
      }
    },
  },

  mutations: {
    /**
     * Set the lat and lng
     * @param {object} state  [the state]
     * @param {object} latLng [the lat and lng to use]
     */
    setAddress(state, address) {
      state.address = address
    },

    /**
     * Set the array of points for the address
     * @param {state} state [the state]
     * @param {object} data  [the data for the address from vb gov esri]
     */
    setAddressPolygon(state, data) {
      try {
        var polyCoor = []
        for (var c in data.features[0].geometry.rings[0]) {
          polyCoor.push(data.features[0].geometry.rings[0][c].reverse())
        }

        state.addressPolygon = polyCoor
      } catch (e) {
        this.commit("address/setError", true)
      }
    },

    /**
     * Show hide info button
     * @param {object} state
     * @param {boolean} data
     */
    setInfoButton(state, data) {
      state.showInfoButton = data
    },

    /**
     * Set error
     * @param {object} [state]
     * @param {string} status [string for the the loading]
     */
    setError(state, status) {
      state.error = status
    },
  },
}

export default Address
