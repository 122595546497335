import Vue from "vue"
import Vuex from "vuex"
import { Capacitor } from "@capacitor/core"
import { Device } from "@capacitor/device"
import { checkForExpiredToken } from "../utils/misc.js"
import Home from "./home"
import MyVB from "./myvb"
import News from "./news"
import Navigation from "./navigation"
import Address from "./address"
import Alerts from "./alerts"
import Explore from "./explore"
import Services from "./services"
import Events from "./events"
import Apps from "./apps"
import Notifications from "./notifications"
import Map from "./map"
import Auth from "./auth"
import Settings from "./settings"
import storage from "../utils/storage.js"

export default new Vuex.Store({
  state: {
    defaultError: "Please reload/refresh, an error was detected. Contact comitmobileteam@vbgov.com if it still occurs.",
    errorMessage: "Please reload/refresh, an error was detected. Contact comitmobileteam@vbgov.com if it still occurs.",
    deviceInfo: undefined,
  },

  modules: {
    home: Home,
    myvb: MyVB,
    news: News,
    navigation: Navigation,
    address: Address,
    alerts: Alerts,
    explore: Explore,
    events: Events,
    services: Services,
    apps: Apps,
    notifications: Notifications,
    map: Map,
    auth: Auth,
    settings: Settings,
  },

  mutations: {
    async initialiseStore(state) {
      var storeSqlIte = await storage.getItem("store")
      storeSqlIte = JSON.parse(storeSqlIte)
      this.replaceState(Object.assign(state, storeSqlIte))

      // check for account if it exists

      if (Capacitor.isNativePlatform()) {
        try {
          if (checkForExpiredToken(state.auth.authResponse.idTokenClaims.exp) == true) {
            // console.log("checknative token = EXPIRED")
            this.dispatch("auth/clearAll")
          } else {
            //console.log("native token = NOT EXPIRED")
          }
        } catch (error) {
          //console.log("native token error = " + error)
        }
      } else {
        let accountStatus = await this.dispatch("auth/checkForAccount")
        if (accountStatus === true) {
          this.dispatch("auth/isExpired")
        } else {
          this.dispatch("auth/clearAll")
        }
      }
    },

    async setDeviceInfo(state, deviceInfo) {
      state.deviceInfo = await Device.getInfo()
    },
    /**
     * sets the error message
     * @param {*} state
     * @param {*} message
     */
    setErrorMessage(state, message) {
      state.errorMessage = message
    },
  },
  actions: {
    /**
     * sets the error message
     * @param {*} param0
     * @param {*} message - if undefined/null use default
     */
    setErrorMessage({ commit, state }, message) {
      let error = message

      if (message === undefined || message === null) {
        error = state.defaultError
      }

      commit("setErrorMessage", error)
    },
  },
})
