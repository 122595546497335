import axios from "axios"
import api from "../utils/api.js"
import storage from "../../utils/storage.js"

const Home = {
  namespaced: true,

  state: {
    addresses: [],
    userAddress: "",
    gpin: "",
    details: "",
    loading: "hidden",
    error: false,
    submitStatus: {
      opacity: "opacity-25",
      disabled: 1,
    },
    verified: false,
  },

  getters: {
    userAddress: async (state) => {
      if (state.userAddress.length === 0 && storage.getItem !== undefined) {
        const cachedUserAddress = await storage.getItem("userAddress")
        return cachedUserAddress || ""
      } else {
        return state.userAddress
      }
    },
    addresses: (state) => {
      return state.addresses
    },
    details: (state) => {
      return state.details
    },
    gpin: (state) => {
      return state.gpin
    },
    loading: (state) => {
      return state.loading
    },
    error: (state) => {
      return state.error
    },
    submitStatus: (state) => {
      return state.submitStatus
    },
    /**
     * Fetches if the user is logged in or not
     *
     * @param {*} state - current app state
     * @returns boolean if the user is logged in or not
     */
    verified: async (state) => {
      if (state.userAddress.length === 0 && storage.getItem !== undefined) {
        const cachedUserAddress = await storage.getItem("userAddress")
        return cachedUserAddress
      } else {
        return state.verified
      }
    },
  },

  actions: {
    /**
     * Look up addresses
     * @param  {function} options.commit [commit function]
     * @param  {string} param          [street term to look up]
     */
    async getAddresses({ commit, dispatch }, param) {
      try {
        let postData = { param, getAddress: true, getGpin: false, getDetails: false }
        var response = await axios.post(process.env.VUE_APP_PROPERTY_URL, postData, {
          headers: { "Content-Type": "application/json" },
        })

        commit("setAddresses", response["data"])

        if (response["data"] !== undefined && response["data"] !== null && response.data.streets.length === 0) {
          dispatch("setErrorMessage", "There were no matching addresses found - please try again.", {
            root: true,
          })
          commit("setError", true)
        }
      } catch (e) {
        commit("setError", true)
      }
    },

    /**
     * Get the gpin
     * @param  {function} options.commit [commit function]
     * @param  {string} param          [street lookup]
     */
    async getGpin({ commit }, param) {
      try {
        let postData = { param, getAddress: false, getGpin: true, getDetails: false }
        var response = await axios.post(process.env.VUE_APP_PROPERTY_URL, postData, {
          headers: { "Content-Type": "application/json" },
        })
        commit("setUserGpin", response.data)
      } catch (e) {
        commit("setError", true)
      }
    },

    /**
     * Get the details
     * @param  {function} options.commit [commit function]
     * @param  {string} param          [details lookup]
     */

    async getPropertyDetails({ commit }, param) {
      try {
        let postData = { param, getAddress: false, getGpin: false, getDetails: true }
        var response = await axios.post(process.env.VUE_APP_PROPERTY_URL, postData, {
          headers: { "Content-Type": "application/json" },
        })
        commit("setUserDetails", response.data)
      } catch (e) {
        commit("setError", true)
      }
    },

    /**
     * Get the address
     * @param  {function} options.commit [commit function]
     * @param  {string} param          [details lookup]
     */

    async getAddress({ commit }, param) {
      try {
        var response = await api.get(
          `https://geo.vbgov.com/mapservices/rest/services/GeoServices/Beach_Coder/GeocodeServer/reverseGeocode?f=pjson&featureTypes=PointAddress,StreetAddress&location=${param.long},+${param.lat}`
        )
        commit("setUserAddress", response.data.address.Address)
        commit("setSubmitStatus", { disabled: 0, opacity: "opacity-100" })
      } catch (e) {
        this.dispatch("setErrorMessage", response.data.error.details[0])
        commit("setError", true)
      }
    },
  },
  mutations: {
    /** Reset Db */
    resetDb() {
      storage.clearDb()
    },
    /**
     * Save addresses to state
     * @param  {[type]} state [description]
     * @param  {[type]} data  [description]
     * @return {[type]}       [description]
     */
    setAddresses(state, data) {
      var streetData = []
      for (var i = 0; i < data.streets.length; i++) {
        streetData.push(data.streets[i]["situsStreet"])
      }
      state.addresses = streetData
    },

    /**
     * Reset addresses
     * @param  {object} state [the state]
     */
    async resetAddresses(state) {
      state.addresses = []
    },

    /**
     * Set the user address
     * @param {object} state
     * @param {[string} address
     */
    async setUserAddress(state, address) {
      state.userAddress = address
      storage.setItem("userAddress", address)
    },

    /**
     * Set the gpin
     * @param {object} [state]
     * @param {object} [json]
     */
    setUserGpin(state, json) {
      try {
        state.gpin = json.data[0].gpin
        this.dispatch("home/getPropertyDetails", state.gpin)
      } catch (e) {
        //console.log("e = " + e);
      }
    },

    /**
     * Set the details
     * @param {object} [state]
     * @param {object} [json]
     */
    setUserDetails(state, json) {
      try {
        state.details = json.data[0]
        this.commit("home/setVerification", true)
      } catch (e) {
        //console.log("e = " + e);
      }
    },

    /**
     * Set loading
     * @param {object} [state]
     * @param {string} status [string for the the loading]
     */
    setLoading(state, status) {
      state.loading = status
    },

    /**
     * Set error
     * @param {object} [state]
     * @param {string} status [string for the the loading]
     */
    setError(state, status) {
      state.error = status
    },

    setSubmitStatus(state, data) {
      state.submitStatus = data
    },
    /**
     * Sets the verification state
     *
     * @param {*} state - current state
     * @param {*} data - if the user is logged in or not
     */
    setVerification(state, verified) {
      state.verified = verified
    },
  },
}

export default Home
