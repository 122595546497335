<template>
  <div
    class="w-full flex flex-col container"
    :class="backgroundImage"
    :style="{ backgroundImage: `url(${backgroundImageFile})` }"
  >
    <Nav :class="navShowHide" :pageTitle="this.$route.name" />
    <router-view class="flex-auto overflow-auto self-stretch" />
  </div>
</template>

<script>
import backgroundWelcome_0 from "./assets/imgs/beach-welcome_0.webp"
import backgroundWelcome_1 from "./assets/imgs/beach-welcome_1.webp"
import backgroundWelcome_2 from "./assets/imgs/beach-welcome_2.webp"
import backgroundWelcome_3 from "./assets/imgs/beach-welcome_3.webp"
import backgroundWelcome_4 from "./assets/imgs/beach-welcome_4.webp"
import backgroundWelcome_5 from "./assets/imgs/beach-welcome_5.webp"
import backgroundWelcome_6 from "./assets/imgs/beach-welcome_6.webp"
import backgroundWelcome_7 from "./assets/imgs/beach-welcome_7.webp"
import backgroundWelcome_8 from "./assets/imgs/beach-welcome_8.webp"
import backgroundWelcome_9 from "./assets/imgs/beach-welcome_9.webp"
import backgroundWelcome_10 from "./assets/imgs/beach-welcome_10.webp"
import backgroundWelcome_11 from "./assets/imgs/beach-welcome_11.webp"
import Nav from "@/components/Nav.vue"
import { SplashScreen } from "@capacitor/splash-screen"
import Azureb2cplugin from "@/utils/pluginConnect"
import { Capacitor } from "@capacitor/core"
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"

const backgroundImageFiles = [
  backgroundWelcome_0,
  backgroundWelcome_1,
  backgroundWelcome_2,
  backgroundWelcome_3,
  backgroundWelcome_4,
  backgroundWelcome_5,
  backgroundWelcome_6,
  backgroundWelcome_7,
  backgroundWelcome_8,
  backgroundWelcome_9,
  backgroundWelcome_10,
  backgroundWelcome_11,
]

export default {
  components: { Nav },
  data: function () {
    return {
      navShowHide: "hidden",
      backgroundImage: "",
      backgroundImageFile: "",
      analyticsInitialized: null,
    }
  },
  watch: {
    $route() {
      if (this.analyticsInitialized != null) {
        this.logAnalytics(this.$route.fullPath, this.$route.name)
      }

      if (this.$route.fullPath == "/" || this.$route.fullPath == "/?action=reset") {
        var fileNum = Math.floor(Math.random() * 10)
        this.navShowHide = "hidden"
        this.backgroundImage = "welcomeScreenBackground"
        this.backgroundImageFile = backgroundImageFiles[fileNum]
      } else {
        this.navShowHide = ""
        this.backgroundImage = ""
        this.backgroundImageFile = ""
      }
    },
  },
  methods: {
    // /**
    //  * Call analytics
    //  * @param  {string} route [path for the route]
    //  */
    logAnalytics(route, screenName, error) {
      console.log("Logging analytics for route: " + screenName)
      try {
        FirebaseAnalytics.logEvent({
          name: "setScreenName",
          params: {
            screenName: screenName,
          },
        })
      } catch (error) {
        console.error(error)
      }
    },
  },
  /**
   * Page has mounted
   */
  async mounted() {
    SplashScreen.hide()

    // Adding to handle issue with iOS keyboard triggering viewport resize on show but not on hide
    window.addEventListener("focusout", () => {
      setTimeout(function () {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
      }, 250)
    })

    //Display Tost message from mobile host
    if (Capacitor.isNativePlatform()) {
      Azureb2cplugin.addListener("displayToastMessage", (data) => {
        const message = data.message
        this.$toast.clear()
        this.$toast.open({
          message: data.message,
          type: data.type,
          position: "top",
          class: "v-toast--top",
          duration: 5000,
        })
      })
    }
    this.analyticsInitialized = await FirebaseAnalytics.initializeFirebase({
      apiKey: "AIzaSyCDxANV1Lo3cPfNWFuOSx0qfAh4va2M5ps",
      authDomain: "ancient-house-718.firebaseapp.com",
      databaseURL: "https://ancient-house-718.firebaseio.com",
      projectId: "ancient-house-718",
      storageBucket: "ancient-house-718.appspot.com",
      messagingSenderId: "580523859069",
      appId: "1:580523859069:web:0c5d3dc85baf3e2c70e514",
      measurementId: "G-FXZ4N4RZPC",
    })

    if (process.env.NODE_ENV !== "production") {
      FirebaseAnalytics.setCollectionEnabled({ enabled: false })
    }
  },
}
</script>

<style>
/* I tried setting this via tailwind and was unsuccessful. */
.container {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  overflow: auto;
  height: 100vh;
  max-width: 100% !important;
}
</style>
