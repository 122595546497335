<template>
  <button aria-label="open login" class="mr-4" v-if="!isLoggedIn">
    <font-awesome-icon
      icon="user-circle"
      class="text-3xl"
      style="width: 34px; height: 34px"
      @click="$emit('login-click')"
    />
  </button>
</template>

<script>
export default {
  name: "LoginBtn",
  props: {
    isLoggedIn: Boolean,
  },
}
</script>
