import store from "../../store"
import AuthService from "@/services/AuthService"
import {
  msalConfig,
  apiConfig,
  b2cPolicies,
  loginRequest,
  tokenRequest,
} from "@/services/AuthServiceConfig"
import { checkForExpiredToken } from "@/utils/misc.js"

const authservice = new AuthService()

export const handleRedirectResponse = response => {
  const that = this
  if (response) {
    if (
      response.idTokenClaims["tfp"].toUpperCase() ===
      b2cPolicies.names.signUpSignIn.toUpperCase()
    ) {
      store.dispatch("auth/handleResponse", response);
    }
  }
};

authservice.app.handleRedirectPromise()
  .then(response => {
    handleRedirectResponse(response);
  })
  .catch(error => {
    console.log(error);
  });
  
/**
 * Events
 */
const Auth = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    initials: "",
    fullName: "",
    user: {},
    authResponse: {},
  },
  mutations: {
    updateIsAuthenticated(state, value) {
      state.isAuthenticated = value
    },
    updateInitials(state, string) {
      var names = string.split(" "),
        initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }

      state.initials = initials
    },
    updateUser(state, value) {
      state.user = value
    },
    updateAuthResponse(state, value) {
      state.authResponse = value
    },
  },
  actions: {
    /**
     * Login user
     */
    login({ commit }) {
      authservice.login()
    },

    /**
     * Logout user
     */
    logout({ commit }) {
      authservice.logout()
      commit("updateIsAuthenticated", false)
    },

    /**
     * Handle response back from msal authentication
     */
    handleResponse({ commit }, response) {
      if (response !== null) {
        authservice.setAccount(response.account)
      } else {
        authservice.selectAccount()
      }

      commit("updateIsAuthenticated", authservice.isUserLogged())
      commit("updateInitials", authservice.fullName)
      commit("updateAuthResponse", response)
    },

    /**
     * Check for account in Session storage
     */
    checkForAccount({ commit }) {
      authservice.selectAccount()
      return authservice.isUserLogged()
    },

    /**
     * Is the token expired
     */
    isExpired({ commit }) {
      // console.log(
      //   "authservice.account.idTokenClaims.exp = " +
      //     authservice.account.idTokenClaims.exp
      // )

      // console.log(
      //   "Token expired = " +
      //     checkForExpiredToken(authservice.account.idTokenClaims.exp)
      // )

      try {
        if (
          checkForExpiredToken(authservice.account.idTokenClaims.exp) === true
        ) {
          store.dispatch("auth/reAuth")
        }
      } catch (error) {
        store.dispatch("auth/clearAll")
      }
    },

    /**
     * Clear all login info stored in local storage
     */
    clearAll({ commit }) {
      store.commit("auth/updateIsAuthenticated", false)
      store.commit("auth/updateInitials", "")
      store.commit("auth/updateUser", {})
      store.commit("auth/updateAuthResponse", {})
    },

    /**
     * Reauthenticate the user
     */
    async reAuth({ commit }) {
      let data = await authservice.reauth()
      store.dispatch("auth/handleResponse", data)
    },
  },

  getters: {
    getAuthenticated: state => {
      return state.isAuthenticated
    },
    getInitials: state => {
      return state.initials
    },
    getAuthResponse: state => {
      return state.authResponse
    },
  },
}

export default Auth
