<template>
  <div class="outer mr-4" v-if="isLoggedIn">
    <div v-if="initials.length > 0">
      <div class="inner initials mt-1.5" v-if="platform == 'ios'">
        {{ initials }}
      </div>
      <div class="inner initials mt-1" v-if="platform != 'ios'">
        {{ initials }}
      </div>
      <font-awesome-icon
        icon="circle"
        class="text-3xl"
        style="width: 34px; height: 34px"
      />
    </div>
    <div v-else-if="initials.length == 0">
      <font-awesome-icon
        icon="user-check"
        class="text-3xl"
        style="width: 34px; height: 34px"
      />
    </div>
  </div>
</template>

<style scoped>
.initials {
  color: #fff;
  text-align: center;
  width: 34px;
}
.outer {
  display: table;
  float: right;
}
.inner {
  position: absolute;
}
</style>
<script>
import { Capacitor } from "@capacitor/core"

export default {
  name: "Badge",
  props: {
    initials: {
      required: true,
      type: String,
    },

    isLoggedIn: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    /**
     * Gets the current platform
     *
     * @returns the current platform
     */
    platform: function() {
      return Capacitor.getPlatform()
    },
  },
}
</script>
