// src/store/map/index.js
import api from "../utils/api.js"
const graphql = require("graphql")
const { print } = graphql

const Map = {
  namespaced: true,

  state: {
    liveParking: null,
  },
  getters: {
    liveParking: (state) => {
      return state.liveParking
    },
  },
  mutations: {
    setLiveParking(state, data) {
      state.liveParking = data
    },
  },
  actions: {
    async loadLiveParking({ commit, dispatch }, param) {
      try {
        const response = await api.post(process.env.VUE_APP_PARKING_URL, {
          "Content-Type": "application/json",
        })

        commit("setLiveParking", response.data.data.getParking)
      } catch (e) {
        console.log(e)
        dispatch("setErrorMessage", "Failed to retrieve live parking data!")
        commit("setError", true)
      }
    },
  },
}

export default Map
