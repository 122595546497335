import api from "../utils/api.js"

const Explore = {
  namespaced: true,

  state: {
    menu: [],
    selectedItems: {},
    checked: undefined,
  },

  getters: {
    menu: (state) => {
      return state.menu
    },
    selectedItems: (state) => {
      return state.selectedItems
    },
    checked: (state) => {
      return state.checked
    },
  },

  actions: {
    /**
     * Look up addresses
     * @param  {function} options.commit [commit function]
     * @param  {string} param          [street term to look up]
     */
    async getMenu({ commit }) {
      try {
        var response = await api.get(process.env.VUE_APP_EXPLORE_MENU_URL)
        commit("setMenu", response["data"])
      } catch (e) {
        //commit("setError", true);
      }
    },
  },

  mutations: {
    /**
     * Set the menu data
     * @type {Object}
     */
    setMenu(state, data) {
      state.menu = data
    },

    /**
     * Set the selectedItems data
     * @type {Object}
     */
    setSelectedMenuItems(state, data) {
      state.selectedItems = data
    },

    /**
     * Set the selectedItems data
     * @type {Object}
     */
    setChecked(state, checked) {
      state.checked = checked
    },
  },
}

export default Explore
