<template>
  <li class="parent" v-if="menuItem.isExternal">
    <a
      target="_blank"
      @click="handleClicked()"
      :class="anchorClassObj"
      class="flex justify-between text-white px-4 py-3"
    >
      <p class="inline">{{ menuItem.name }}</p>
      <i class="far fa-external-link mt-1"></i>
    </a>
  </li>
</template>
<style scoped>
a:hover {
  background: rgba(0, 0, 0, 0.2);
  color: white;
}
</style>
<script>
import { Browser } from "@capacitor/browser"
import { Device } from "@capacitor/device"

export default {
  name: "ExternalMenuItem",
  data() {
    return {
      isExpanded: false,
    }
  },
  props: {
    menuItem: {
      required: true,
      type: Object,
    },
    menuArr: {
      required: true,
      type: Array,
    },
  },
  computed: {
    /**
     * update anchor tag based on a variety of parameter
     */
    anchorClassObj: function() {
      return {}
    },
  },
  methods: {
    /**
     * menu item clicked listener
     */
    handleClicked() {
      if (this.menuItem.isParent) {
        this.isExpanded = !this.isExpanded
      } else {
        if (this.menuItem.name.includes("Waze") && this.$store.state.home.userAddress.length > 0) {
          let addressDetails = this.$store.state.home.details
          this.$emit("click-action")
          Browser.open({ url: "https://waze.com/ul?ll=" + addressDetails.latitude + "%2C" + addressDetails.longitude })
        } else {
          this.$emit("click-action")
          Browser.open({ url: this.menuItem.url })
        }
      }
    },
  },
}
</script>
