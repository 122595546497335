import { Capacitor } from "@capacitor/core"

var test = ""
var redirect = ""
var clientId = ""

if (process.env.NODE_ENV === "production") {
  test = ""
  redirect = "https://vbconnect.vbgov.com/"
  clientId = "8eccd68e-c1bb-4c62-a870-d2faa7c89e4a"

  if (Capacitor.isNativePlatform()) {
    redirect = "https://d2m2ncxxiu4rfl.cloudfront.net/"
  }

  if (window.location.href.includes("http://localhost:8080/")) {
    redirect = "http://localhost:8080/"
  }
} else {
  test = "Test"
  redirect = "https://vbconnecttest.vbgov.com/"
  clientId = "8eccd68e-c1bb-4c62-a870-d2faa7c89e4a"

  if (Capacitor.isNativePlatform()) {
    redirect = "https://d2m2ncxxiu4rfl.cloudfront.net/"
  }

  if (window.location.href.includes("http://192.168.0.100:8100")) {
    redirect = "https://d2m2ncxxiu4rfl.cloudfront.net/"
  }
}

let apiConfig = {
  b2cScopes: [`https://vbgovB2C.onmicrosoft.com/tasks-api/tasks.read`],
  webApi: "http://localhost:5000/hello",
}

let b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_signupsignin1",
    editProfile: "B2C_1_profileediting1",
  },
  authorities: {
    signUpSignIn: {
      authority: `https://vbgovB2C.b2clogin.com/vbgovB2C.onmicrosoft.com/B2C_1_signupsignin1`,
    },
    editProfile: {
      authority: `https://vbgovB2C.b2clogin.com/vbgovB2C.onmicrosoft.com/B2C_1_profileediting1`,
    },
  },
  authorityDomain: `vbgovB2C.b2clogin.com`,
}

let msalConfig = {
  auth: {
    clientId: clientId, // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: redirect, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
}

let loginRequest = {
  scopes: ["openid", "profile", ...apiConfig.b2cScopes],
}

let tokenRequest = {
  scopes: [...apiConfig.b2cScopes], // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
}

export { apiConfig, b2cPolicies, msalConfig, loginRequest, tokenRequest }
