import { Browser } from "@capacitor/browser"
import { FCM } from "@capacitor-community/fcm"
import { PushNotifications } from "@capacitor/push-notifications"
import router from "@/router"
import store from "../store"

class PushNotificationManager {}

/**
 * Get the channel
 * @param {object} channel
 * @returns channel name+
 */
PushNotificationManager.getChannelName = (channel) => {
  var channelName = channel.id
  if (process.env.NODE_ENV !== "production") {
    channelName = `${channelName}-${process.env.NODE_ENV}`
  }

  return channelName
}

/**
 * Unsubscribe for iOS
 * @param {object} channel
 */
PushNotificationManager.unsubscribe = async (channel) => {
  try {
    await FCM.unsubscribeFrom({ topic: PushNotificationManager.getChannelName(channel) })
    store.commit("notifications/setSubscribeToChannel", {
      channel: channel,
      status: false,
    })
  } catch (error) {
    // alert(error)
  }
}

/**
 * Subscribe for iOS
 * @param {object} channel
 */
PushNotificationManager.subscribe = async (channel) => {
  try {
    await FCM.subscribeTo({ topic: PushNotificationManager.getChannelName(channel) })
    store.commit("notifications/setSubscribeToChannel", {
      channel: channel,
      status: true,
    })
  } catch (error) {
    // alert(error)
  }
}

/**
 * Register for iOS
 */
PushNotificationManager.register = async () => {
  try {
    await PushNotifications.requestPermissions()
    await PushNotifications.register()
    await FCM.getToken()

    let channels = store.state.notifications.channels
    for (var i = 0; i < channels.length; i++) {
      if (channels[i].subscribed == undefined) {
        PushNotificationManager.subscribe(channels[i])
      }
    }

    PushNotifications.addListener("pushNotificationActionPerformed", async (notification) => {
      PushNotificationManager.actionPerformed(notification)
    })
  } catch (error) {
    // alert(error)
  }
}

/**
 * Action performed
 * @param {object} notification
 */
PushNotificationManager.actionPerformed = async (notification) => {
  if (notification.notification.data.url.length > 0) {
    let url = notification.notification.data.url
    Browser.open({ url: url })
  }
}

export { PushNotificationManager }
