import api from "../utils/api.js"
import { alerts } from "../utils/queries.js"
const graphql = require("graphql")
const { print } = graphql

const Alerts = {
    namespaced: true,
    state: {
        alerts: [],
    },
    getters: {
        /**
         * Fetches alerts from the store state
         */
        alerts: (state) => {
            return state.alerts;
        },
    },
    actions: {
        /**
         * Load alerts from web service
         */
        async loadAlerts({ commit }) {
            //TODO:This needs to be re-implemented
            // const pass = process.env.VUE_APP_VBGOV_AUTH;
            // const token = process.env.VUE_APP_VBGOV_TOKEN;
            // const auth = pass ? `Basic ${pass}, Bearer ${token}`:`Bearer ${token}`;
            const response = await api.get(process.env.VUE_APP_ALERTS_URL, {
                headers: {
                "Content-Type": "application/json",
                },
            })
            const data = response.data.data.sitewideAlertsEntries
            const mappedData = data.map((item)=>{
                const alert = item
                let rt = {
                    GUID: alert.id,
                    Title: alert.title,
                    Description: alert.simpleRichText,
                    IconId: alert.icon[0].id,
                    IconName: alert.icon[0].filename,
                    IconImage: alert.icon[0].img,
                    Level: alert.color,
                    // LinkField: {
                    //     customText: alert.linkField.customText,
                    //     target: alert.linkField.target,
                    //     text: alert.linkField.text,
                    //     title: alert.linkField.title,
                    //     url: alert.linkField.url
                    // }
                }
                
                return rt
            })
            
            commit("setAlerts", mappedData)
        },
    },
    mutations: {
        /**
         * Sets the alerts to the store state
         *
         * @param {Object} state store state
         * @param {Array[Object]} data array of alerts
         */
        setAlerts(state, data) {
            state.alerts = data;
        },
    },
};

export default Alerts;
