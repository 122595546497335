const Notifications = {
  namespaced: true,
  state: {
    channels: [
      {
        id: "common",
        name: "Alerts",
        subscribed: undefined,
      },
      {
        id: "news_releases",
        name: "News Release",
        subscribed: undefined,
      },
    ],
  },
  /**
   * Gets channels
   * specified then return all news items regardless of type
   *
   * @returns {Array} of channels
   */
  getters: {
    getChannels: state => {
      return state.channels
    },
  },

  mutations: {
    /**
     *
     * Subscribe to particular channel
     * @param {object} state
     * @param {object} channelData
     */
    setSubscribeToChannel(state, channelData) {
      var found = state.channels.findIndex(x => x.id === channelData.channel.id)
      state.channels[found].subscribed = channelData.status
    },
  },
}

export default Notifications
