import api from "../utils/api.js"
import { getApps } from "../utils/queries.js"
const graphql = require("graphql")
const { print } = graphql
import { Capacitor } from "@capacitor/core"

const Apps = {
  namespaced: true,

  state: {
    apps: {},
  },

  getters: {
    /**
     * Gets dictionary of apps, sorted by category
     */
    appsData: state => {
      return state.apps
    },
  },

  actions: {
    /**
     * Fetches navigation items from web service
     *
     * @param {*} { commit }
     */
    async loadApps({ commit }) {
      /**
       * Inline comparision for apps
       * @param {String} a first app to compare
       * @param {String} b second app to compare
       */
      function compare(a, b) {
        const appA = a.Title.toUpperCase()
        const appB = b.Title.toUpperCase()

        let comparison = 0
        if (appA > appB) {
          comparison = 1
        } else if (appA < appB) {
          comparison = -1
        }
        return comparison
      }

      const response = await api.get(process.env.VUE_APP_APPS_URL, {
        headers: {
          "Content-Type": "application/json",
        },
      })

      // Take raw JSON and sort into categories and then sort each category
      const allEntries = Object.values(await response.data)
      let data = response.data
      commit("setApps", data)
    },
  },

  mutations: {
    /**
     * Sets the navigation items
     *
     * @param {*} state - current state
     * @param {*} data - new navigation items
     */
    setApps(state, data) {
      state.apps = data
    },
  },
}

export default Apps
