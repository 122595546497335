const parser = require("parse-address");

function createPropertyBoundsQuery(param) {
	var parsedAddress = parser.parseLocation(param);
	var addressQuery = "";
	for (var part in parsedAddress) {
		switch (part) {
			case "number":
				addressQuery += `ST_NUM = '${parsedAddress[part]}' and `;
				break;
			case "street":
				addressQuery += `ST_NAME = '${parsedAddress[part]}' and `;
				break;
			case "type":
				addressQuery += `ST_TYPE = '${parsedAddress[part]}' and `;
				break;
			case "prefix":
				addressQuery += `ST_DIR = '${parsedAddress[part]}' and `;
				break;
			case "zip":
				addressQuery += `ZIP = '${parsedAddress[part]}'`;
				break;
		}
	}
	return addressQuery.replace(/ and +$/, "");
}

export { createPropertyBoundsQuery };
