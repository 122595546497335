import api from "../utils/api.js"
import moment from "moment"

import { LocalNotifications } from "@capacitor/local-notifications"

const MyVB = {
  namespaced: true,

  state: () => ({
    wasteCollectionDay: "",
    garbageDate: "",
    garbageDay: "",
    recycleDate: "",
    recycleDay: "",
    libraryMeta: {},
    eventsMeta: {},
    parksRecMeta: {},
    parkMeta: {},
    detailLastUpdate: "",
    wasteNotify: false,
    wasteNotifyDay: 1,
    wasteNotifyHour: 17,
    wasteNotifyMin: 0,
  }),

  getters: {
    garbageDate: (state) => {
      return state.garbageDate
    },
    garbageDay: (state) => {
      return state.garbageDay
    },
    recycleDate: (state) => {
      return state.recycleDate
    },
    recycleDay: (state) => {
      return state.recycleDay
    },
    libraryMeta: (state) => {
      return state.libraryMeta
    },
    parksRecMeta: (state) => {
      return state.parksRecMeta
    },
    parkMeta: (state) => {
      return state.parkMeta
    },
    detailLastUpdate: (state) => {
      return state.detailLastUpdate
    },
    wasteNotify: (state) => {
      return state.wasteNotify
    },
    wasteNotifyDay: (state) => {
      return state.wasteNotifyDay
    },
    wasteNotifyHour: (state) => {
      return state.wasteNotifyHour
    },
    wasteNotifyMin: (state) => {
      return state.wasteNotifyMin
    },
  },

  actions: {
    init(store) {},

    /**
     * Fetches items from web service
     * @param {*} param0
     */
    async loadLocations({ commit, rootGetters }) {
      const response = await api.get(process.env.VUE_APP_LOCATION_URL)
      const data = await response.data.data

      commit("setLocationData", data)

      let library = rootGetters["home/details"].closestLibrary
      if (library !== undefined && library !== null) {
        let adjusted = library.replace("Library", "").trim()
        let found = data.venue.filter((item) => item.name.includes(adjusted))

        if (found.length > 0) {
          commit("setLibraryMeta", found[0])
        }
      }

      let recCenter = rootGetters["home/details"].closestRecCenter
      if (recCenter !== undefined && recCenter !== null) {
        let adjusted = recCenter.replace("Recreation Center", "").trim()
        let found = data.parksrec.filter((item) => item.name.includes(adjusted))

        if (found.length > 0) {
          commit("setParksRecMeta", found[0])
        }
      }
    },

    /**
     * Fetches park details from web service
     * @param {*} param0
     */
    async loadPark({ commit, rootGetters }) {
      let park = rootGetters["home/details"].closestPark

      if (park !== undefined && park !== null) {
        let url = `${process.env.VUE_APP_PARK_FILTER_URL}/${park}`

        const response = await api.get(url)
        const data = await response.data.data[0]

        if (data !== undefined && data !== null) {
          commit("setParkMeta", data)
        }
      }
    },

    /**
     * Check if property details is considered stale & update if so
     * @param {*} param0
     */
    async checkStaleDetails({ dispatch, state, commit, rootGetters }) {
      if (isDateStaleFrom(state.detailLastUpdate)) {
        let gpin = rootGetters["home/gpin"]

        await dispatch("home/getPropertyDetails", gpin, { root: true })
        dispatch("refreshTrashInfo")

        commit("setDetailLastUpdate", moment().format())
      }
    },

    /**
     * Update trash/recycle info
     * @param {*} param0
     */
    refreshTrashInfo({ commit, rootGetters }) {
      let trashDt = rootGetters["home/details"].wasteCollectionDay
      let recycleDt = rootGetters["home/details"].nextRecyclingDate

      commit("setGarbageDate", trashDt)
      commit("setGarbageDay", trashDt)
      commit("setRecycleDate", recycleDt)
      commit("setRecycleDay", recycleDt)
    },

    /**
     * update waste notification time
     * @param {*} param0
     * @param {*} day (expected 0 - 6)
     * @param {*} hour (expected 0 - 23)
     * @param {*} minute (expected 0 - 59)
     */
    updateWasteTime({ commit }, day, hour, minute) {
      if (!isNaN(day)) {
        day = Math.abs(day)

        commit("setWasteNotifyDay", day)
      }

      if (!isNaN(hour)) {
        hour = Math.abs(hour)

        commit("setWasteNotifyHour", hour)
      }

      if (!isNaN(minute)) {
        minute = Math.abs(minute)

        commit("setWasteNotifyMin", minute)
      }
    },

    /**
     * update waste notification flag based on if pending LocalNotifications are found.
     * @param {*} param0
     */
    async checkLocalNotificationState({ commit }) {
      let pending = await LocalNotifications.getPending()
      commit("setWasteNotify", pending.notifications.length > 0)
    },
  },
  mutations: {
    /**
     * Set the garbage date
     * @param {object} state
     * @param {json} data  [json data that holds the date]
     */
    setGarbageDate(state, data) {
      var dt = nextDayAndTime(data)
      dt = dt.getMonth() + 1 + "/" + dt.getDate()
      state.garbageDate = dt
    },

    /**
     * Set the garbage day
     * @param {object} state
     * @param {json} data  [json data that holds the date]
     */
    setGarbageDay(state, data) {
      //console.log("data.home.details.wasteCollectionDay ==== " + data.home.details.wasteCollectionDay);
      var dt = nextDayAndTime(data)
      state.garbageDay = lookupDay(dt.getDay())
    },

    /**
     * Set the recycle date
     * @param {object} state
     * @param {json} data  [json data that holds the date]
     */
    setRecycleDate(state, data) {
      //console.log("data.home.details.nextRecyclingDate ==== " + data.home.details.nextRecyclingDate);
      let displayDt = getRecycleDate(data)

      state.recycleDate = displayDt
    },

    /**
     * Set the recycle day
     * @param {object} state
     * @param {json} data  [json data that holds the date]
     */
    setRecycleDay(state, data) {
      //console.log("data.home.details.nextRecyclingDate ==== " + data.home.details.nextRecyclingDate);
      var dt = new Date(data)
      state.recycleDay = lookupDay(dt.getDay())
    },

    /**
     * sets the location data
     * @param {*} state
     * @param {*} data
     */
    setLocationData(state, data) {
      state.locationData = data
    },

    /**
     * sets the library meta data
     * @param {*} state
     * @param {*} data
     */
    setLibraryMeta(state, data) {
      state.libraryMeta = data
    },

    /**
     * sets the rec center meta data
     * @param {*} state
     * @param {*} data
     */
    setParksRecMeta(state, data) {
      state.parksRecMeta = data
    },

    /**
     * sets the park meta data
     * @param {*} state
     * @param {*} data
     */
    setParkMeta(state, data) {
      state.parkMeta = data
    },

    /**
     * sets the last update data
     * @param {*} state
     * @param {*} data
     */
    setDetailLastUpdate(state, data) {
      state.detailLastUpdate = data
    },

    /**
     * sets the trash notify data
     * @param {*} state
     * @param {*} data
     */
    setWasteNotify(state, data) {
      state.wasteNotify = data
    },

    /**
     * sets the trash notify day
     * @param {*} state
     * @param {*} data
     */
    setWasteNotifyDay(state, data) {
      state.wasteNotifyDay = data
    },

    /**
     * sets the trash notify hour
     * @param {*} state
     * @param {*} data
     */
    setWasteNotifyHour(state, data) {
      state.wasteNotifyHour = data
    },

    /**
     * sets the trash notify minute
     * @param {*} state
     * @param {*} data
     */
    setWasteNotifyMin(state, data) {
      state.wasteNotifyMin = data
    },
  },
} // end of store MyVB

/**
 * Get the next day and time
 * @param  {string} day [the day to look up]
 * @return {[object}     [next date object for the pickup]
 */
export function nextDayAndTime(day) {
  var now = new Date()
  var numberOfDaysTillPickup
  var trashDayNumber = lookupDayNumber(day)
  var daysLeftInTheWeek = 7 - now.getDay()

  if (trashDayNumber < now.getDay()) {
    numberOfDaysTillPickup = daysLeftInTheWeek + trashDayNumber
  } else {
    numberOfDaysTillPickup = trashDayNumber - now.getDay()
  }

  var date = new Date()
  date.setDate(date.getDate() + numberOfDaysTillPickup)
  date.setHours(0, 0, 0, 0)

  return date
}

/**
 * Look up the day number
 * @param  {number} val [day to use to lookup]
 * @return {number}     [weekday number]
 */
export function lookupDayNumber(val) {
  if (typeof val === "undefined") {
    return ""
  }

  var day = val.toLowerCase()
  var weekdays = new Array(
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
  )
  var weekdayIndex = (element) => element == day
  return weekdays.findIndex(weekdayIndex)
}

/**
 * Look up the day
 * @param  {number} num [day number]
 * @return {string}     [name of day abbr]
 */
function lookupDay(num) {
  var weekdays = new Array("SUN", "MON", "TUES", "WED", "THURS", "FRI", "SAT")
  return weekdays[num]
}

/**
 * @param {*} timestamp
 * @returns True if date provided is greater than 1 day; otherwise false.
 */
export function isDateStaleFrom(timestamp) {
  let isStale = true

  if (timestamp !== undefined && timestamp !== null && timestamp.length > 0) {
    let lastUpdated = moment(timestamp)

    if (lastUpdated.isValid) {
      let now = moment()
      let duration = moment.duration(now.diff(lastUpdated))
      isStale = duration.asDays() > 1
    }
  }

  return isStale
}

/**
 * get reminder date based on date comparison
 * @param {*} trashDt
 * @param {*} recycleDt
 */
export function getReminderDate(
  collectionDt,
  isRecycleDt,
  { wasteNotifyDay, wasteNotifyHour, wasteNotifyMin }
) {
  let reminderDt = moment(collectionDt)
  reminderDt.subtract(wasteNotifyDay, "days")
  reminderDt.hours(wasteNotifyHour)
  reminderDt.minute(wasteNotifyMin)
  reminderDt.second(0) // just in case

  let currentDt = moment()

  if (currentDt.isAfter(reminderDt)) {
    // has the reminder date expired?
    let numOfWeek = 1

    if (isRecycleDt) {
      numOfWeek = 2 // biz rule - every other week
    }

    reminderDt.add(numOfWeek, "weeks") // next week
  }

  // console.log("## ----------------"); // left for verification
  // console.log(`isAfter->${reminderDt.isAfter(currentDt)} ${reminderDt.toString()} || ${currentDt.toString()}`);
  // console.log(`## ${reminderDt.toString()}`);
  // console.log("## ---------------0");

  return reminderDt.toDate()
}

/**
 * Will verify if recycle date is day of & update the display text
 * @param {*} rawDt
 * @returns
 */
export function getRecycleDate(rawDt) {
  let recycleDt = moment(rawDt, "dddd, MMMM D, YYYY")
  let prevRecycleDt = moment(rawDt, "dddd, MMMM D, YYYY")
  prevRecycleDt.subtract(2, "w")

  let formatted

  if (moment().isSame(prevRecycleDt, "day")) {
    formatted = prevRecycleDt.format("M/D")
  } else {
    formatted = recycleDt.format("M/D")
  }

  return formatted
}

export default MyVB
